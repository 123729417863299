import React from 'react'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { Heading3, Caption } from '@components/TextStyles'
import { ErrorStyle, ErrorText } from './index.style'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'

const Error = () => {
  return (
    <ErrorStyle>
      <Container>
        <Spacer size={[100, 300]} />
        <Grid>
          <GridItem>
            <ErrorText>
              <Caption color={colors.pink2}>404 Error</Caption>
              <Spacer size={[16, 29]} />
              <Heading3 color={colors.pink2}>Page Not Found</Heading3>
              <Spacer size={[29, 36]} />
              <Button variant="secondary" type="externalLink" href={'/'}>
                Back to Home
              </Button>
            </ErrorText>
          </GridItem>
        </Grid>
        <Spacer size={[100, 300]} />
      </Container>
    </ErrorStyle>
  )
}

Error.propTypes = {}

export default Error
