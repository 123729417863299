import React from 'react'

const LogoFooter = ({ fill = 'currentColor' }) => {
  return (
    <svg width="91" height="41" fill="none" viewBox="0 0 91 41">
      <path fill={fill} d="m13.612 23.563 1.555-4.328-8.522 4.328h6.967Z" />
      <path fill={fill} d="M77.158 23.563h5.295V5.944l-5.295 3.544v14.075Z" />
      <path fill={fill} d="M85.507 3.9v19.663h5.33V.332L85.507 3.9Z" />
      <path fill={fill} d="M59.387 23.563h5.295V.332l-5.295 4.979v18.25Z" />
      <path
        fill={fill}
        d="M50.762 2.178h-3.89a6.808 6.808 0 0 1 4.656 6.458 6.809 6.809 0 0 1-4.655 6.457h3.89a6.458 6.458 0 1 0 0-12.915Z"
      />
      <path
        fill={fill}
        d="M66.666 10.615 65.13 11.78c2.53 0 3.746 1.452 3.746 3.489v8.294h5.296v-9.439c0-4.074-4.282-5.952-7.505-3.508Z"
      />
      <path fill={fill} d="M.151 23.563h6.05V2.177H.151v21.384Z" />
      <path fill={fill} d="M40.38 23.563h6.05V2.177h-6.05v21.384Z" />
      <path
        fill={fill}
        d="m20.818 3.508-1.813 5.046 2.518 6.54h-4.868l-.387 1.076c3.44 0 6.486 2.142 7.722 5.34l.793 2.052h6.52L22.965 1.884l-2.147 1.624Z"
      />
      <path
        fill={fill}
        d="M79.801 0a2.868 2.868 0 1 0 0 5.737 2.868 2.868 0 0 0 0-5.737Z"
      />
      <path
        fill={fill}
        d="M.151 36.483h.524l.987 2.533.987-2.533h.518v3.56H2.73v-2.68l-.92 2.355h-.3l-.916-2.355v2.68H.15v-3.56Z"
      />
      <path
        fill={fill}
        d="M5.107 38.833v-2.35h.442v2.375c0 .468.265.814.814.814.544 0 .814-.346.814-.814v-2.375h.442v2.35c0 .839-.57 1.251-1.256 1.251-.692 0-1.256-.412-1.256-1.251Z"
      />
      <path
        fill={fill}
        d="m9.324 39.112.367-.228c.101.427.325.788.788.788.325 0 .585-.193.585-.605s-.361-.58-.789-.784c-.401-.193-.747-.462-.747-.971 0-.509.381-.87.895-.87.493 0 .758.234.91.58l-.36.229c-.128-.31-.336-.397-.55-.397-.285 0-.463.203-.463.432 0 .382.35.545.732.718.448.203.82.473.82 1.043 0 .63-.443 1.037-1.038 1.037a1.16 1.16 0 0 1-1.15-.972Z"
      />
      <path fill={fill} d="M13.303 36.483h.442v3.56h-.442v-3.56Z" />
      <path
        fill={fill}
        d="M15.557 38.263c0-1.22.778-1.82 1.607-1.82.712 0 1.21.396 1.389 1.037l-.418.112c-.137-.448-.442-.738-.971-.738-.656 0-1.15.483-1.15 1.41 0 .925.494 1.408 1.15 1.408.529 0 .834-.29.971-.738l.418.113c-.178.64-.677 1.037-1.389 1.037-.829 0-1.607-.6-1.607-1.82Z"
      />
      <path
        fill={fill}
        d="M24.088 37.195c0-.219-.137-.392-.397-.392-.234 0-.391.153-.391.397 0 .209.127.407.249.565.325-.158.54-.316.54-.57Zm.214 2.162-.865-1.12c-.315.173-.514.433-.514.804 0 .387.27.652.656.652a.878.878 0 0 0 .723-.336Zm-1.79-.32c0-.57.37-.926.696-1.1-.132-.182-.305-.452-.305-.737 0-.468.35-.758.788-.758.494 0 .794.316.794.712 0 .438-.356.728-.697.906l.748.966c.096-.178.183-.386.27-.646l.264.31a3.31 3.31 0 0 1-.28.662l.54.691h-.494l-.29-.371a1.25 1.25 0 0 1-.967.412c-.62 0-1.068-.382-1.068-1.048Z"
      />
      <path
        fill={fill}
        d="m30.966 38.675-.544-1.597-.544 1.597h1.088Zm-.753-2.192h.418l1.256 3.56h-.463l-.325-.956h-1.353l-.326.956h-.468l1.261-3.56Z"
      />
      <path
        fill={fill}
        d="M34.68 37.943c.387 0 .606-.153.606-.524 0-.366-.219-.524-.605-.524h-.738v1.048h.738Zm-1.18-1.46h1.196c.656 0 1.037.336 1.037.936 0 .508-.274.819-.742.91l1.068 1.714h-.493l-1.064-1.688h-.56v1.688h-.441v-3.56Z"
      />
      <path
        fill={fill}
        d="M38.308 36.895h-1.043v-.412h2.523v.412h-1.043v3.148h-.437v-3.148Z"
      />
      <path fill={fill} d="M41.391 36.483h.443v3.56h-.443v-3.56Z" />
      <path
        fill={fill}
        d="m43.58 39.112.365-.228c.102.427.326.788.789.788.325 0 .585-.193.585-.605s-.362-.58-.789-.784c-.402-.193-.748-.462-.748-.971 0-.509.382-.87.896-.87.493 0 .757.234.91.58l-.36.229c-.128-.31-.337-.397-.55-.397-.285 0-.463.203-.463.432 0 .382.35.545.732.718.448.203.82.473.82 1.043 0 .63-.443 1.037-1.038 1.037a1.16 1.16 0 0 1-1.15-.972Z"
      />
      <path
        fill={fill}
        d="M48.087 36.895h-1.044v-.412h2.523v.412h-1.042v3.148h-.437v-3.148Z"
      />
      <path fill={fill} d="M51.17 36.483h.442v3.56h-.442v-3.56Z" />
      <path
        fill={fill}
        d="M53.403 38.263c0-1.22.778-1.82 1.607-1.82.713 0 1.211.396 1.39 1.037l-.418.112c-.137-.448-.443-.738-.972-.738-.655 0-1.149.483-1.149 1.41 0 .925.494 1.408 1.15 1.408.528 0 .834-.29.97-.738l.418.113c-.178.64-.676 1.037-1.389 1.037-.829 0-1.607-.6-1.607-1.82Z"
      />
      <path
        fill={fill}
        d="M61.523 39.632c.63 0 1.24-.372 1.24-1.369s-.61-1.368-1.24-1.368h-.484v2.736h.484Zm-.926-3.15h.992c.89 0 1.632.627 1.632 1.781 0 1.155-.742 1.78-1.632 1.78h-.992v-3.56Z"
      />
      <path fill={fill} d="M65.001 36.483h.443v3.56H65v-3.56Z" />
      <path
        fill={fill}
        d="M68.66 37.943c.387 0 .606-.153.606-.524 0-.366-.219-.524-.606-.524h-.737v1.048h.737Zm-1.18-1.46h1.196c.656 0 1.037.336 1.037.936 0 .508-.274.819-.742.91l1.068 1.714h-.493l-1.064-1.688h-.559v1.688h-.442v-3.56Z"
      />
      <path
        fill={fill}
        d="M71.706 36.483h2.07v.412H72.15v1.048h1.322v.407H72.15v1.282h1.678v.411h-2.12v-3.56Z"
      />
      <path
        fill={fill}
        d="M75.338 38.263c0-1.22.778-1.82 1.607-1.82.712 0 1.21.396 1.388 1.037l-.417.112c-.137-.448-.442-.738-.971-.738-.657 0-1.15.483-1.15 1.41 0 .925.493 1.408 1.15 1.408.529 0 .834-.29.971-.738l.417.113c-.178.64-.676 1.037-1.388 1.037-.83 0-1.607-.6-1.607-1.82Z"
      />
      <path
        fill={fill}
        d="M80.71 36.895h-1.043v-.412h2.523v.412h-1.043v3.148h-.437v-3.148Z"
      />
      <path
        fill={fill}
        d="M86.209 38.263c0-.946-.524-1.409-1.144-1.409-.626 0-1.145.463-1.145 1.41 0 .945.519 1.408 1.145 1.408.62 0 1.144-.463 1.144-1.409Zm-2.746 0c0-1.164.742-1.82 1.602-1.82.854 0 1.597.656 1.597 1.82 0 1.165-.743 1.821-1.597 1.821-.86 0-1.602-.656-1.602-1.82Z"
      />
      <path
        fill={fill}
        d="M89.622 37.943c.386 0 .605-.153.605-.524 0-.366-.219-.524-.605-.524h-.738v1.048h.738Zm-1.18-1.46h1.195c.656 0 1.038.336 1.038.936 0 .508-.275.819-.743.91L91 40.043h-.493l-1.063-1.688h-.56v1.688h-.442v-3.56Z"
      />
      <path
        fill={fill}
        d="M0 30.782c0-1.832 1.204-2.744 2.492-2.744 1.157 0 1.908.682 2.184 1.656l-.79.207c-.245-.66-.705-1.073-1.394-1.073-.92 0-1.626.636-1.626 1.954 0 1.342.705 1.955 1.61 1.955.859 0 1.272-.56 1.319-1.257H2.729v-.72h1.894v2.705h-.714l-.084-.651c-.23.383-.69.712-1.387.712-1.273 0-2.438-.912-2.438-2.744Z"
      />
      <path
        fill={fill}
        d="M7.389 31.526v-3.427h.85v3.45c0 .743.361 1.188 1.105 1.188.736 0 1.096-.445 1.096-1.188v-3.45h.85v3.427c0 1.357-.835 2-1.946 2-1.12 0-1.955-.643-1.955-2Z"
      />
      <path
        fill={fill}
        d="m13.812 32.04.698-.43c.153.613.483 1.127 1.119 1.127.437 0 .774-.23.774-.759 0-.537-.575-.774-1.211-1.088-.56-.277-1.066-.683-1.066-1.472 0-.79.598-1.38 1.434-1.38.751 0 1.204.352 1.449.927l-.683.437c-.168-.437-.452-.575-.766-.575-.353 0-.613.253-.613.56 0 .498.52.736 1.088.989.652.299 1.227.682 1.227 1.564 0 .966-.698 1.586-1.656 1.586-.95 0-1.633-.674-1.794-1.487Z"
      />
      <path
        fill={fill}
        d="M20.665 28.889h-1.526v-.79h3.902v.79h-1.526v4.576h-.85V28.89Z"
      />
      <path
        fill={fill}
        d="m27.295 31.334-.675-2.108-.682 2.108h1.357Zm-1.096-3.235h.835l1.855 5.366h-.912l-.43-1.341h-1.855l-.436 1.341h-.905L26.2 28.1Z"
      />
      <path
        fill={fill}
        d="M30.2 28.1h.927l1.326 4.17 1.334-4.17h.928l-1.825 5.365h-.866L30.2 28.1Z"
      />
      <path
        fill={fill}
        d="M40.54 30.782c0-1.341-.744-1.954-1.61-1.954-.866 0-1.61.613-1.61 1.954 0 1.342.744 1.955 1.61 1.955.866 0 1.61-.613 1.61-1.955Zm-4.086 0c0-1.817 1.15-2.744 2.476-2.744s2.476.927 2.476 2.744c0 1.817-1.15 2.744-2.476 2.744s-2.476-.927-2.476-2.744Z"
      />
      <path
        fill={fill}
        d="M49.07 32.675c.898 0 1.833-.436 1.833-1.893 0-1.464-.935-1.893-1.832-1.893h-.53v3.786h.53ZM47.69 28.1h1.603c1.295 0 2.476.866 2.476 2.683 0 1.817-1.18 2.683-2.476 2.683H47.69V28.1Z"
      />
      <path
        fill={fill}
        d="M54.39 31.526v-3.427h.85v3.45c0 .743.361 1.188 1.104 1.188.736 0 1.097-.445 1.097-1.188v-3.45h.85v3.427c0 1.357-.835 2-1.947 2-1.118 0-1.954-.643-1.954-2Z"
      />
      <path
        fill={fill}
        d="M62.53 32.675c.897 0 1.832-.436 1.832-1.893 0-1.464-.935-1.893-1.832-1.893h-.529v3.786h.53ZM61.15 28.1h1.602c1.296 0 2.476.866 2.476 2.683 0 1.817-1.18 2.683-2.476 2.683H61.15V28.1Z"
      />
      <path
        fill={fill}
        d="m70.103 31.334-.674-2.108-.683 2.108h1.358Zm-1.096-3.235h.835l1.856 5.366h-.912l-.43-1.341h-1.855l-.437 1.341h-.905l1.848-5.366Z"
      />
      <path
        fill={fill}
        d="M74.02 28.1h.973l1.365 3.556 1.364-3.557h.966v5.366h-.843v-3.633l-1.188 3.143h-.598l-1.189-3.143v3.633h-.85V28.1Z"
      />
      <path
        fill={fill}
        d="M81.646 28.1h3.235v.789h-2.384v1.326h1.924v.782h-1.924v1.678h2.461v.79h-3.312V28.1Z"
      />
      <path fill={fill} d="M87.64 28.1h.85v4.575h2.347v.79H87.64V28.1Z" />
    </svg>
  )
}

export default LogoFooter
