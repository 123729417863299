import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { easings } from '@styles/vars/easings.style'
import { font } from '@styles/vars/font.style'
import { transparentize } from 'polished'
import { mq } from '@styles/vars/media-queries.style'
import { ButtonMain } from '@components/Button/index.style'

export const FooterMain = styled.div`
  position: relative;

  &:before {
    background-color: ${transparentize(0.88, colors.darkblue)};
    content: '';
    height: 0.1rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`
export const ArrowButton = styled.div`
  display: flex;
  justify-content: flex-end;

  ${ButtonMain} {
    background: transparent;
    border-color: ${colors.midgrey};
    transform: rotateZ(-90deg);
  }
`
export const FooterCaption = styled.div`
  display: none;

  ${mq.desk} {
    display: block;
  }
`

export const FooterLink = styled.p`
  ${clamp('font-size', 16, 18)}
  font-weight: ${font.primary.weight.regular};
  line-height: 1.4;
  color: ${colors.dark};
  margin-bottom: 0.8rem;

  a {
    display: inline-block;
    position: relative;
    text-decoration: none;

    &:before {
      background-color: ${colors.dark};
      bottom: 0;
      content: '';
      height: 0.1rem;
      left: 0;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transform-origin: 0% 0%;
      transition: transform 0.5s ${easings.inOut.default};
    }

    &:hover {
      &:before {
        transform: scaleX(1);
      }
    }
  }
`

export const FooterLocaleLink = styled.a`
  pointer-events: ${props => (props.current ? `none` : `auto`)};

  &:before {
    transform: scaleX(${props => (props.current ? 1 : 0)}) !important;
  }

  &:hover {
    &:before {
      transform: scaleX(1) !important;
    }
  }
`

export const FooterBlock = styled.div`
  ${clamp('padding-top', 48, 92)}
`

export const FooterTextSmall = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  color: ${colors.midgrey};

  a {
    transition-property: border-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ${easings.inOut.default};

    &:hover {
      border-color: ${colors.pink2};
      color: ${colors.pink2};
    }
  }
`
