import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import {
  FooterMain,
  Column,
  ArrowButton,
  FooterCaption,
  FooterLink,
  FooterLocaleLink,
  FooterBlock,
  FooterTextSmall,
} from './index.style'
import { useStore } from '@Store/'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Container from '@components/Container'
import Spacer from '@components/Spacer'
import { TextBodySmall } from '@styles/vars/textStyles.style'
import { colors } from '@styles/vars/colors.style'
import IconArrowRight from '@svgs/IconArrowRight'
import Button from '@components/Button'
import PageTransitionLink from '@components/PageTransitionLink'
import { scrollToPoint } from '@utils'
import LogoFooter from '@svgs/LogoFooter'
import { animation } from '@styles/vars/animation.style'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { localise } from '@utils/localise'

const Footer = () => {
  const [store] = useStore()
  const { smoothScroll } = store
  const preLogo =
    typeof document !== 'undefined' ? document.getElementById('pre-logo') : null

  const {
    contentfulGlobals: {
      leftColumnFooter,
      rightColumnFooter,
      footerPresentedByText,
    },
  } = useStaticQuery(
    graphql`
      query {
        contentfulGlobals {
          leftColumnFooter {
            ...Link
          }
          rightColumnFooter {
            ...Link
          }
          footerPresentedByText {
            footerPresentedByText
          }
        }
      }
    `
  )

  return (
    <FooterMain>
      <Container>
        <Spacer size={[24, 80]} />
        <Grid>
          <GridItem mobile={6} desk={1} deskStart={1}>
            <AnimateSlideIn rootMargin="0px 0px -20px 0px">
              <a href="https://www.laphil.com/" target="_blank">
                <LogoFooter />
              </a>
            </AnimateSlideIn>
          </GridItem>

          <GridItem
            mobile={6}
            tabletP={1}
            tabletPStart={12}
            desk={1}
            deskStart={12}
            deskOrder={6}
          >
            <ArrowButton>
              <AnimateSlideIn rootMargin="0px 0px -20px 0px">
                <Button
                  iconRight={<IconArrowRight fill={colors.midgrey} />}
                  onClick={() => {
                    if (preLogo) preLogo.focus()
                    scrollToPoint(smoothScroll, 0)
                  }}
                  iconOnly={true}
                  label={localise('Back to top')}
                />
              </AnimateSlideIn>
            </ArrowButton>
          </GridItem>

          <GridItem
            mobile={6}
            tabletP={2}
            tabletPStart={1}
            desk={2}
            deskOrder={2}
            deskStart={3}
            deskM={1}
            deskMStart={3}
          >
            <Column>
              {React.Children.toArray(
                leftColumnFooter.map((link, linkIndex) => {
                  return (
                    <>
                      {link.page && (
                        <FooterLink>
                          <AnimateSlideIn
                            rootMargin="0px 0px -20px 0px"
                            delay={animation.lineDuration * linkIndex}
                          >
                            <PageTransitionLink
                              to={link.page.slug ? `/${link.page.slug}` : '/'}
                            >
                              {link.text}
                            </PageTransitionLink>
                          </AnimateSlideIn>
                        </FooterLink>
                      )}

                      {link.url && (
                        <FooterLink>
                          <AnimateSlideIn
                            rootMargin="0px 0px -20px 0px"
                            delay={animation.lineDuration * linkIndex}
                          >
                            <a href={link.url} target="_blank">
                              {link.text}
                            </a>
                          </AnimateSlideIn>
                        </FooterLink>
                      )}
                    </>
                  )
                })
              )}
            </Column>
          </GridItem>

          <GridItem
            mobile={6}
            tabletP={2}
            tabletPStart={3}
            desk={2}
            deskOrder={3}
            deskStart={5}
            deskM={1}
            deskMStart={4}
          >
            <Column>
              {React.Children.toArray(
                rightColumnFooter.map((link, linkIndex) => {
                  return (
                    <>
                      {link.page && (
                        <FooterLink>
                          <AnimateSlideIn
                            rootMargin="0px 0px -20px 0px"
                            delay={animation.lineDuration * linkIndex}
                          >
                            <PageTransitionLink
                              to={
                                link.page.slug
                                  ? link.text === 'Podcast'
                                    ? `/${link.page.slug}?podcast`
                                    : `/${link.page.slug}`
                                  : '/'
                              }
                            >
                              {link.text}
                            </PageTransitionLink>
                          </AnimateSlideIn>
                        </FooterLink>
                      )}

                      {link.url && (
                        <FooterLink>
                          <AnimateSlideIn
                            rootMargin="0px 0px -20px 0px"
                            delay={animation.lineDuration * linkIndex}
                          >
                            <a href={link.url} target="_blank">
                              {link.text}
                            </a>
                          </AnimateSlideIn>
                        </FooterLink>
                      )}
                    </>
                  )
                })
              )}
            </Column>
          </GridItem>

          <GridItem
            tabletP={2}
            tabletPStart={5}
            desk={1}
            deskOrder={4}
            deskStart={7}
            deskM={1}
            deskMStart={5}
          >
            <FooterLink>
              <AnimateSlideIn
                rootMargin="0px 0px -20px 0px"
                delay={animation.lineDuration}
              >
                <FooterLocaleLink
                  href="https://first100years.hollywoodbowl.com/"
                  current={process.env.GATSBY_CONTENTFUL_LOCALE === 'en-US'}
                >
                  EN
                </FooterLocaleLink>
                /
                <FooterLocaleLink
                  href="https://es.first100years.hollywoodbowl.com/"
                  current={process.env.GATSBY_CONTENTFUL_LOCALE === 'es-US'}
                >
                  ES
                </FooterLocaleLink>
              </AnimateSlideIn>
            </FooterLink>
          </GridItem>

          <GridItem
            desk={3}
            deskStart={9}
            deskOrder={5}
            contained={2}
            containedStart={10}
          >
            <FooterCaption>
              <TextBodySmall>
                <AnimateSplitText rootMargin="0px 0px -20px 0px">
                  {footerPresentedByText.footerPresentedByText}
                </AnimateSplitText>
              </TextBodySmall>
            </FooterCaption>
          </GridItem>

          <GridItem
            desk={3}
            deskStart={9}
            deskOrder={8}
            contained={3}
            containedStart={10}
            containedOrder={7}
          >
            <FooterBlock>
              <FooterTextSmall>
                <AnimateSplitText rootMargin="0px 0px -20px 0px">
                  {localise('Website by')}{' '}
                  <a
                    href="https://toyfight.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ToyFight
                  </a>
                </AnimateSplitText>
              </FooterTextSmall>
            </FooterBlock>
          </GridItem>

          <GridItem desk={6} deskOrder={7} deskStart={3} alignment={`center`}>
            <FooterBlock>
              <FooterTextSmall>
                <AnimateSplitText rootMargin="0px 0px -20px 0px">
                  {localise(
                    '©2022 LA Phil. All rights reserved. Use of this site constitutes acceptance of our user agreement and privacy policy and cookie statement.'
                  )}
                </AnimateSplitText>
              </FooterTextSmall>
            </FooterBlock>
          </GridItem>
        </Grid>

        <Spacer size={65} />
      </Container>
    </FooterMain>
  )
}

Footer.propTypes = {}

export default Footer
