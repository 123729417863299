import Error from '@components/404'
import Footer from '@components/Footer'
import React from 'react'

const NotFoundPage = () => {
  return (
    <>
      <Error />
      <Footer />
    </>
  )
}

export default NotFoundPage
